import { Injectable } from '@angular/core';
import { NbGlobalLogicalPosition, NbToastrService } from '@nebular/theme';
import { colorHelper, helpMessage, helpTitle, notFoundMessage } from '../standard';
@Injectable({
  providedIn: 'root',
})
export class ToastService {

  constructor(private toasterService: NbToastrService) { }
  showConfigError() {
    try {
      this.toasterService.show(helpMessage, helpTitle, {
        preventDuplicates: false,
        destroyByClick: true,
        status: colorHelper.danger,
        // duration: 3000,
        limit: 3, position: NbGlobalLogicalPosition.TOP_END,
      });
    } catch (Ex) {

    }
  }

  showNotFoundInApi() {
    this.toasterService.show(notFoundMessage, helpTitle, {
      preventDuplicates: false,
      destroyByClick: true,
      status: colorHelper.danger,
      // duration: 3000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END,
    });
  }
  showSuccess(message: string) {
    this.toasterService.show(message, helpTitle, {
      preventDuplicates: false,
      destroyByClick: true,
      status: colorHelper.success,
      duration: 5000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END,
    });
  }
  showError(message: string) {
    this.toasterService.show(message, helpTitle, {
      preventDuplicates: false,
      destroyByClick: true,
      status: colorHelper.danger,
      duration: 5000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END,
    });
  }
  showWarning(message: string) {
    this.toasterService.show(message, helpTitle, {
      preventDuplicates: false,
      destroyByClick: true,
      status: colorHelper.warning,
      duration: 5000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END,
    });
  }

  showNotification(message: string) {
    this.toasterService.show(message, helpTitle, {
      preventDuplicates: false,
      destroyByClick: true,
      status: colorHelper.info,
      duration: 5000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END,
      icon: {
        icon: 'bell-outline',
        pack: 'eva',
      },
    });
  }

  showInvalidLogin() {
    this.toasterService.show('Invalid login credentials try again!', helpTitle, {
      preventDuplicates: false,
      destroyByClick: true,
      status: colorHelper.danger,
      // duration: 3000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END,
    });
  }
}
