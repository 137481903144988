import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created with ♥ by <b><a href="https://vaasits.com" target="_blank">VAAS ITS</a></b> {{year}}
    </span>
  `,
})
export class FooterComponent implements OnInit {
  year = 2022;
  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

}
