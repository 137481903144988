import * as moment from 'moment';
import { FormGroup } from "@angular/forms";

export const helpTitle = `Cliniqally`;
export const helpMessage =
  'Something went wrong! Rest assured, we are working on it.';
export const notFoundMessage = 'Requested Api not available.!';
export const tokenName = "Dev-DoctorApp";

export function JwtTokenParser(token: string) {
  if (token && token.length > 0) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
      return JSON.parse(jsonPayload);
    } catch (ex) {
      return null;
    }
  } else {
    return null;
  }
}
export function urlEncode(inputString: string) {
  const en = decodeURIComponent(inputString);
  return en;
}
export function checkIfImageExist(imageUrl: string | undefined) {
  return imageUrl && imageUrl != 'null' ? urlEncode(imageUrl) : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6swZO8d-ZWsYcFSMuRaipYJhGTDvJVJ-dIg&usqp=CAU';
}
export interface countryType {
  country_id: string,
  name: string
}

export interface stateType {
  state_id: string,
  name: string
}

export interface cityType {
  city_id: string,
  name: string
}

export interface NbChatMessage {
  sender: string;
  message: string;
  date: Date;
}

export interface nbChatMessageRegCertificate {
  customMessageData: any;
  sender: string;
  message: string;
  date: any;
}

export function decodeText(inputString: string) {
  const decodedText = decodeURIComponent(inputString);
  const txt = document.createElement("textarea");
  txt.innerHTML = decodedText;
  return txt.value;
}

function userTypeCheck(token: string, type: userType) {
  if (token) {
    const jwtData = JwtTokenParser(token);
    return jwtData.userType === type;
  } else {
    return false;
  }
}
//todo:need to fix this

export enum userType {
  admin = 'admin-user',
  company = 'company-user',
  entity = 'entity-user',
  normal = 'normal-user',
}

//todo:need to fix this

export function isCompanyUser(token: string) {
  return token ? userTypeCheck(token, userType.company) : false;
}
//todo:need to fix this

export function hasAccess(
  token: any,
  moduleName: moduleName,
  accessLevel: accessLevel,
) {
  try {
    const ModuleName = token?.permissions[moduleName];
    if (ModuleName) {
      const AccessLevel = ModuleName[accessLevel];
      return !!AccessLevel;
    }
    return false;
  } catch (exception) {

    return false;
  }
}

export enum accessLevel {
  create = 'create',
  update = 'update',
  delete = 'delete',
  view = 'view',
}

export enum moduleName {
  Employee = 'Employee',
  Entity = 'Entity',
  'Rig/Warehouse' = 'Rig/Warehouse',
  Buy = 'Buy',
  Sell = 'Sell',
  Company = 'Company',
}

export function isEntityUser(token: string) {
  if (token) {
    return userTypeCheck(token, userType.entity);
  }
  return false;
}

export function isAdminUser(token: string) {
  if (token) {
    return userTypeCheck(token, userType.admin);
  }
  return false;
}

export function isNormalUser(token: string) {
  if (token) {
    return userTypeCheck(token, userType.normal);
  }
  return false;
}
//todo: may need change
export class ApiReturn {
  status?: any;
  reauth?: boolean;
  message: string = "";
  DetailedMessage?: string;
  StatusCode?: StatusCode;
  data?: any;
  token?: string;
  UserToken?: string;
  url?: string;
}

export enum colorHelper {
  basic = 'basic',
  primary = 'primary',
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  control = 'control',
}

export enum StatusCode {
  OK = 'OK',
  NotFound = 'NotFound',
  InternalServerError = 'InternalServerError',
  InvalidLoginError = 'InvalidLoginError',
}

export function findInvalidInForm(f: FormGroup) {
  const invalid: string[] = [];
  const controls = f.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  console.table(invalid);
  return invalid;
}

export enum RestMethod {
  get,
  post,
  put,
  update,
  delete,
  patch,
}

export enum userTypeEnum {
  admin = "admin",
  doctor = "doctor",
  staff = "staff",
  patient = "patient"
}
export enum subscriptionIntervalEnum {
  Weekly = "Weekly",
  Monthly = "Monthly",
  Annually = "Annually"
}
export enum usersTypeEnum {
  Clinic = "Clinic",
  Service_Provider = "Service_Provider"
}
export enum subscriptionTypeEnum {
  Full = "Full",
  Add_On = "Add_On"
}
export enum subscriptionPlanTypeEnum {
  Trial = "Trial",
  Free = "Free",
  Paid = "Paid"
}
export enum subscriptionDiscountTypeEnum {
  Amount = 'Amount',
  Percentage = 'Percentage',
  None = 'None'
}

export enum subscriptionStatusEnum {
  Ongoing = "Ongoing",
  Expired = "Expired",
  Cancelled = "Cancelled"
}
export interface SpecializationType {
  id?: number;
  specialization_id?: string;
  sub_specializations?: [
    {
      sub_specialization_id: string;
      experience: number;
    }
  ];
}

export enum ContentType {
  json = 'application/json',
  formData = 'multipart/form-data;',
}

export function setToken(token: string) {
  return localStorage.setItem(tokenName, token);
}

export function getToken() {
  return localStorage.getItem(tokenName);
}

export function removeToken() {
  return localStorage.removeItem(tokenName);
}

export function setItem(name: string, token: string) {
  return localStorage.setItem(name, token);
}

export function getItem(name: string) {
  return localStorage.getItem(name);
}

export function removeItem(name: string) {
  return localStorage.removeItem(name);
}


export function convertUtcToLocal(utcTime: string) {
  const localDate = moment.utc(utcTime, 'YYYY-MM-DD h:mm A').local().format('DD-MM-YYYY H:mm:ss');
  return localDate;
}

export class CliniqallyAdminAppBaseRestApiJson {
  pathParameters?: string;
  url?: string;
  restMethod?: RestMethod;
  contentType?: ContentType;
  authentication = false;
  queryParameters?: string;
  uploadFormData?: FormData;
  stories?: string;
}

// API integration classes start here

export class CliniqallyAdminAppLoginRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  email: string;
  password: string;
}

export class CliniqallyCreateAdminAppDataClassRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  name?: string;
  status?: string;
  description?: string;
}

export class CliniqallyCreateAdminAppSurgeryClassRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  status?: string;
  surgery_name?: string;
}

export class CliniqallyCreateAdminHabitAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  habit?: string;
}

export class CliniqallyCreateAdminComplaintAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  chief_complaint?: string;
  chiefComplaintDescription?: string;
  specialization?: string;
}

export class CliniqallyUpdateAdminComplaintAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  chiefComplaint?: string;
  chiefComplaintDescription?: string;
  specialization?: string;
}

export class CliniqallyCreateAdminLeaveAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  leaveDetail?: string;
  reason?: string;
}

export class CliniqallyCreateAdminTaxAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  taxName?: string;
  taxValue?: string;
}

export class CliniqallyCreateAdminVerificationAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  verified?: string;
}

export class CliniqallyUpdateConsumerQueryAdminAppDataClassRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  consumer_id?: string;
  query_id?: string;
  status?: string;
}

export class CliniqallyUpdateProviderQueryAdminAppDataClassRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  provider_id?: string;
  query_id?: string;
  status?: string;
}

export class CliniqallyAdminSpecialtyAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  clinic_speciality?: string;
  specialityDescription?: string;
}

export class CliniqallyAdminStateAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  country?: string;
}

export class CliniqallyAdminProviderClinicAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  providerId?: string;
  centerId?: string;
  userType?: string;
}

export class CliniqallyAdminDegreeAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  degree?: string;
}

export class CliniqallyAdminCityAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  country?: string;
  state?: string;
}

export class CliniqallyAdminCurrencyAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  currency?: string;
  symbol?: string;
  symbol_at?: string;
}

export class CliniqallyAdminSpecializationAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  specialization?: string;
  degree?: string;
}

export class CliniqallyAdminOccupationAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  occupation?: string;
}

export class CliniqallyAdminSubSpecializationAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  sub_specialization?: string;
  specialization?: string;
}

export class CliniqallyAdminBloodGroupAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  blood_group?: string;
}
export class CliniqallyAdminMarketingChannelAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  channel_name?: string;
  channel_id?: string;
}

export class CliniqallyAdminTimeZoneAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  time_zone?: string;
}

export class CliniqallyAdminCouncilAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  registration_council_name?: string;
}

export class CliniqallyAdminTypeQueryAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  query_type?: string;
  service_provider: boolean;
  service_consumer: boolean;
}

export class CliniqallyAdminReasonAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  query_reason?: string;
  type_id?: string;
  service_consumer?: boolean;
  service_provider?: boolean;
  query_type?: string;
}

export class CliniqallyAdminSubscriptionPlanAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  subscription_interval?: string;
  user_type?: string;
  subscription_type?: string;
  subscription_plan_type?: string;
  provider_limit?: string;
  discount?: string;
  subscription_days?: string;
  discount_type?: string;
  unit_price?: string;
  currency?: string;
  currency_name?: string;
  subscription_id?: string;
  tax?: string;
  tax_name?: string;

}

export class CliniqallyAdminClinicLicenseAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  start_date?: string;
  subscription_status?: string;
  clinic_id?: string;
  is_paid?: boolean;
  is_expired?: boolean;
  subscription_plan_id?: string;
  comments?: string;
}

export class CliniqallyAdminAppVerifyproviderRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  email: string;
  country_code: string;
  mobile_number: string;
}

export class CliniqallySearchClinicAdminAppRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  email: string;
  country_code: string;
  phone_number: string;
  name: string;
  cliniqally_center_id: string;
}

export class CliniqallyAdminAppAddproviderRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  firstName?: string;
  lastName?: string;
  pincode?: string;
  about?: string;
  userType?: string;
  email?: string;
  language?: [];
  specialization?: SpecializationType[];
  degree?: [];
  awards?: string;
  membership?: string;
  dob?: string;
  gender?: string;
  registrationNumber?: string;
  registrationName?: string;
  timezone?: string;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  providerAgreement?: boolean;
  whatsappNotifications?: boolean;
  blood_donation?: boolean;
  countryCode?: string;
  mobile?: string;
  bloodGroup?: string;
  marketing_channel_id?: string;
}

export class CliniqallyUpdateAdminAppAddProviderRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  firstName: string;
  lastName: string;
  pincode: string;
  about: string;
  email: string;
  dob: string;
  gender: string;
  timezone: string;
  address: string;
  country: string;
  state: string;
  city: string;
  countryCode: string;
  mobileNumber: string;
  blood_group: string;
}

export class CliniqallyAdminMedicalProcedureAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  procedureName?: string;
  panel?: string;
  procedureCode?: string;
  procedurePrice?: string;
  taxApplicable?: string;
  taxType?: string;
}

export class CliniqallyAdminFAQAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  faqTitle?: string;
  faqBody?: string;
  mobileIos?: boolean;
  mobileAndroid?: boolean;
  serviceProvider?: boolean;
  serviceConsumer?: boolean;
  web?: boolean;
}

export class CliniqallyGlobalSearchConsumerAdminAppRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  email: string;
  country_code: string;
  mobile: string;
  first_name: string;
  last_name: string;
  cliniqally_consumer_id: string;
}

export class CliniqallyAdminAppGlobalSearchProviderRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  email: string;
  country_code: string;
  mobile: string;
  first_name: string;
  last_name: string;
  cliniqally_provider_id: string;
}

export class CliniqallyAdminAcceptDocumentAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  clinic_registration_certificate_object: {
    _id?: string;
    status?: string;
    reason?: string;
  }
}
export class CliniqallyAdminAppGlobalSearchCenterRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  email: string;
  country_code: string;
  mobile: string;
  name: string;
  cliniqally_center_id: string;
}

export class CliniqallyAdminAppPaymentAddClinicRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  provider_id: string;
  center_id: string;
  mode_name: string;
  mode_description: string;
  status: string;
  payment_mode_types: string;
  is_available_offline: boolean;
  is_available_online: boolean;
  account_holder_name: string;
  account_number: string;
  bank_branch: string;
  bank_name: string;
  ifsc_code: string;
}
export class CliniqallyAdminAppTextStoriesRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  text: string;
}

export class CliniqallyAdminAppImageStoriesRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  stories_attachment: string;
}

export class CliniqallyAdminUpdateClinicAppDataClassRestApiJson extends CliniqallyAdminAppBaseRestApiJson {
  cliniqally_fee_disabled?: boolean;
}

// API integration classes ends

// API integration related interfaces starts here
export const postLoginJson: CliniqallyAdminAppLoginRestApiJson = {
  url: 'v1/admin/login',
  restMethod: RestMethod.post,
  authentication: false,
  email: '',
  password: '',
};

export const getLanguageJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/language/get-language",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postLanguageJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/language/create-language",
  restMethod: RestMethod.post,
  authentication: true,
  name: "",
  status: "",
};

export const getlanguageByIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/language/get-language",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const deleteLanguageJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/language/delete-language",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const getCountryJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/location/list-country",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getAllCountryJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/location/getAllCountries",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postCountryJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/location/create-country",
  restMethod: RestMethod.post,
  authentication: true,
  name: "",
  status: "",
};

export const getcountryByIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/location/country",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const deleteCountryJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/location/delete-country",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};


export const getStateJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/location/list-state",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getSelectedStateJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/location/list-all-state-in-country",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const postStateJson: CliniqallyAdminStateAppDataClassRestApiJson = {
  url: "v1/admin/location/create-state",
  restMethod: RestMethod.post,
  authentication: true,
  name: "",
  status: "",
  country: "",
};

export const deleteStateJson: CliniqallyAdminStateAppDataClassRestApiJson = {
  url: "v1/admin/location/delete-state",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const getStateByIdJson: CliniqallyAdminStateAppDataClassRestApiJson = {
  url: "v1/admin/location/state",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updateStateJson: CliniqallyAdminStateAppDataClassRestApiJson = {
  url: "v1/admin/location/update-state",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  name: "",
  status: "",
  country: "",
};

export const getCityJson: CliniqallyAdminCityAppDataClassRestApiJson = {
  url: "v1/admin/location/list-city",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteCityJson: CliniqallyAdminCityAppDataClassRestApiJson = {
  url: "v1/admin/location/delete-city",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postCityJson: CliniqallyAdminCityAppDataClassRestApiJson = {
  url: "v1/admin/location/create-city",
  restMethod: RestMethod.post,
  authentication: true,
  name: "",
  status: "",
  country: "",
  state: "",
};

export const getCityByIdJson: CliniqallyAdminCityAppDataClassRestApiJson = {
  url: "v1/admin/location/city",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updatecityJson: CliniqallyAdminCityAppDataClassRestApiJson = {
  url: "v1/admin/location/update-city",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  name: "",
  status: "",
  country: "",
};

export const getDegreeJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/list-degree",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getAllDegreeJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/list-all-degree",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteDegreeJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/delete-degree",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postDegreeJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/add-degree",
  restMethod: RestMethod.post,
  authentication: true,
  degree: "",
  status: "",
};

export const getDegreeByIdJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/get-degree",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updateDegreeJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/update-degree",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  degree: "",
};

export const getSpecializationJson: CliniqallyAdminSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/list-specialization",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteSpecializationJson: CliniqallyAdminSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/delete-specialization",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postSpecializationJson: CliniqallyAdminSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/add-specialization",
  restMethod: RestMethod.post,
  authentication: true,
  specialization: "",
  degree: "",
};

export const getSpecializationByIdJson: CliniqallyAdminSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/get-specialization",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updateSpecialzationJson: CliniqallyAdminSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/update-specialization",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  specialization: "",
};

export const getSubSpecialJson: CliniqallyAdminSubSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/list-sub-specialization",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteSubSpecialJson: CliniqallyAdminSubSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/delete-sub-specialization",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const getAllSpecializationJson: CliniqallyAdminSubSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/get-specialization",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postSubSpecialJson: CliniqallyAdminSubSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/create-sub-specialization",
  restMethod: RestMethod.post,
  authentication: true,
  sub_specialization: "",
  specialization: "",
};

export const updateSubSpecialJson: CliniqallyAdminSubSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/update-sub-specialization",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  sub_specialization: "",
  specialization: "",
};

export const getSubSpecialByIdJson: CliniqallyAdminSubSpecializationAppDataClassRestApiJson = {
  url: "v1/admin/qualification/get-sub-specialization",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getBloodGroupJson: CliniqallyAdminBloodGroupAppDataClassRestApiJson = {
  url: "v1/admin/blood-group/list-blood-group",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteBloodGroupJson: CliniqallyAdminBloodGroupAppDataClassRestApiJson = {
  url: "v1/admin/blood-group/delete-blood-group",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postBloodGroupJson: CliniqallyAdminBloodGroupAppDataClassRestApiJson = {
  url: "v1/admin/blood-group/add-blood-group",
  restMethod: RestMethod.post,
  authentication: true,
  blood_group: "",
  status: "",
};

export const getBloodGroupByIdJson: CliniqallyAdminBloodGroupAppDataClassRestApiJson = {
  url: "v1/admin/blood-group/get-blood-group",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getMarketingChannelJson: CliniqallyAdminMarketingChannelAppDataClassRestApiJson = {
  url: "v1/admin/marketing-channel/list-marketing-channels",
  restMethod: RestMethod.get,
  authentication: true,
};

export interface marketingChannel {
  channel_id: string;
  channel_name: string;
  status: string;
}

export const postMarketingChannelJson: CliniqallyAdminMarketingChannelAppDataClassRestApiJson = {
  url: "v1/admin/marketing-channel/create-marketing-channel",
  restMethod: RestMethod.post,
  authentication: true,
  channel_name: "",
  status: "",
};

export const getMarketingChannelByIdJson: CliniqallyAdminMarketingChannelAppDataClassRestApiJson = {
  url: "v1/admin/marketing-channel/get-marketing-channel",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updateMarketingChannelJson: CliniqallyAdminMarketingChannelAppDataClassRestApiJson = {
  url: "v1/admin/marketing-channel/update-marketing-channel",
  restMethod: RestMethod.post,
  authentication: true,
  channel_id: "",
  status: "",
};

export const getCouncilJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/list-registration-council",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteCouncilJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/delete-registration-council",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postCouncilJson: CliniqallyAdminCouncilAppDataClassRestApiJson = {
  url: "v1/admin/qualification/add-registration-council",
  restMethod: RestMethod.post,
  authentication: true,
  registration_council_name: "",
};

export const updateCouncilJson: CliniqallyAdminCouncilAppDataClassRestApiJson = {
  url: "v1/admin/qualification/update-registration-council",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  registration_council_name: "",
};

export const getCouncilByIdJson: CliniqallyAdminDegreeAppDataClassRestApiJson = {
  url: "v1/admin/qualification/get-registration-council",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getOccupationJson: CliniqallyAdminOccupationAppDataClassRestApiJson = {
  url: "v1/admin/occupation/list-occupations",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteOccupationJson: CliniqallyAdminOccupationAppDataClassRestApiJson = {
  url: "v1/admin/occupation/delete-occupation",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postOccupationJson: CliniqallyAdminOccupationAppDataClassRestApiJson = {
  url: "v1/admin/occupation/add-occupation",
  restMethod: RestMethod.post,
  authentication: true,
  occupation: "",
  status: "",
};

export const updateOccupationJson: CliniqallyAdminOccupationAppDataClassRestApiJson = {
  url: "v1/admin/occupation/update-occupation",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  occupation: "",
  status: "",
};

export const getOccupationByIdJson: CliniqallyAdminOccupationAppDataClassRestApiJson = {
  url: "v1/admin/occupation/get-occupation",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getChronicJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/chronic/get-chronic",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteChronicJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/chronic/delete-chronic",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postChronicJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/chronic/create-chronic",
  restMethod: RestMethod.post,
  authentication: true,
  name: "",
  description: "",
  status: "",
};

export const updateChronicJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/chronic/update-chronic",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
  name: "",
  status: "",
  description: "",
};

export const getChronicByIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/chronic/get-chronic",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getSurgeryJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/surgery/get-surgeries",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteSurgeryJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/surgery/delete-surgery",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postSurgeryJson: CliniqallyCreateAdminAppSurgeryClassRestApiJson = {
  url: "v1/admin/surgery/create-surgery",
  restMethod: RestMethod.post,
  authentication: true,
  status: "",
  surgery_name: "",
};

export const updateSurgeryJson: CliniqallyCreateAdminAppSurgeryClassRestApiJson = {
  url: "v1/admin/surgery/update-surgery",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
  status: "",
  surgery_name: "",
};

export const getSurgeryByIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/surgery/get-surgery",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getAllergyJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/allergy/get-allergies",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteAllergyJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/allergy/delete-allergy",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postAllergyJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/allergy/create-allergy",
  restMethod: RestMethod.post,
  authentication: true,
  name: "",
  description: "",
  status: "",
};

export const updateAllergyJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/allergy/update-allergy",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
  name: "",
  status: "",
  description: "",
};

export const getAllergyByIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/allergy/get-allergy",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getCurrencyJson: CliniqallyAdminCurrencyAppDataClassRestApiJson = {
  url: "v1/admin/currency/list-currency",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteCurrencyJson: CliniqallyAdminCurrencyAppDataClassRestApiJson = {
  url: "v1/admin/currency/delete-currency",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postCurrencyJson: CliniqallyAdminCurrencyAppDataClassRestApiJson = {
  url: "v1/admin/currency/create-currency",
  restMethod: RestMethod.post,
  authentication: true,
  currency: "",
  status: "",
  symbol: "",
  symbol_at: "",
};

export const updateCurrencyJson: CliniqallyAdminCurrencyAppDataClassRestApiJson = {
  url: "v1/admin/currency/update-currency",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  currency: "",
  status: "",
  symbol: "",
  symbol_at: "",
};

export const getCurrencyByIdJson: CliniqallyAdminCurrencyAppDataClassRestApiJson = {
  url: "v1/admin/currency/get-currency",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getTimeZoneJson: CliniqallyAdminBloodGroupAppDataClassRestApiJson = {
  url: "v1/admin/time-zone/list-time-zone",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteTimeZoneJson: CliniqallyAdminBloodGroupAppDataClassRestApiJson = {
  url: "v1/admin/time-zone/delete-time-Zone",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postTimeZoneJson: CliniqallyAdminTimeZoneAppDataClassRestApiJson = {
  url: "v1/admin/time-zone/add-time-zone",
  restMethod: RestMethod.post,
  authentication: true,
  time_zone: "",
  status: "",
};

export const getTimeZoneByIdJson: CliniqallyAdminTimeZoneAppDataClassRestApiJson = {
  url: "v1/admin/time-zone/get-time-zone",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getSpecialityJson: CliniqallyAdminSpecialtyAppDataClassRestApiJson = {
  url: "v1/admin/speciality/list-clinic-speciality",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteSpecialityJson: CliniqallyAdminSpecialtyAppDataClassRestApiJson = {
  url: "v1/admin/speciality/delete-clinic-speciality",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postSpecialtyJson: CliniqallyAdminSpecialtyAppDataClassRestApiJson = {
  url: "v1/admin/speciality/add-clinic-speciality",
  restMethod: RestMethod.post,
  authentication: true,
  clinic_speciality: "",
  status: "",
  specialityDescription: ""
};

export const updateSpecialityJson: CliniqallyAdminSpecialtyAppDataClassRestApiJson = {
  url: "v1/admin/speciality/update-clinic-speciality",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  clinic_speciality: "",
  status: "",
  specialityDescription: ""
};

export const getSpecialtyByIdJson: CliniqallyAdminSpecialtyAppDataClassRestApiJson = {
  url: "v1/admin/speciality/get-clinic-speciality",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getTypeQueryJson: CliniqallyAdminTypeQueryAppDataClassRestApiJson = {
  url: "v1/admin/query/list-query-type",
  restMethod: RestMethod.get,
  authentication: true,
  service_provider: false,
  service_consumer: false,
};

export const deleteTypeQueryJson: CliniqallyAdminTypeQueryAppDataClassRestApiJson = {
  url: "v1/admin/query/delete-query-type",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
  service_provider: false,
  service_consumer: false,
};

export const postTypeQueryJson: CliniqallyAdminTypeQueryAppDataClassRestApiJson = {
  url: "v1/admin/query/create-query-type",
  restMethod: RestMethod.post,
  authentication: true,
  query_type: "",
  status: "",
  service_provider: false,
  service_consumer: false,
};

export const updateTypeQueryJson: CliniqallyAdminTypeQueryAppDataClassRestApiJson = {
  url: "v1/admin/query/update-query-type",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
  query_type: "",
  status: "",
  service_provider: false,
  service_consumer: false,
};

export const getTypeQueryByIdJson: CliniqallyAdminTypeQueryAppDataClassRestApiJson = {
  url: "v1/admin/query/get-query-type",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
  service_provider: false,
  service_consumer: false,
};

export const getConsumerQueryListJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/query/list-consumer-query",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getConsumerQueryByIdJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/query/get-consumer-query",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updateConsumerQueryJson: CliniqallyUpdateConsumerQueryAdminAppDataClassRestApiJson = {
  url: "v1/admin/query/update-query-consumer",
  restMethod: RestMethod.patch,
  authentication: true,
  consumer_id: "",
  status: "",
  query_id: "",
};

export const getProviderQueryListJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/query/list-provider-query",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};
export const getProviderQueryByIdJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/query/get-provider-query",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};
export const updateProviderQueryJson: CliniqallyUpdateProviderQueryAdminAppDataClassRestApiJson = {
  url: "v1/admin/query/update-query-provider",
  restMethod: RestMethod.patch,
  authentication: true,
  provider_id: "",
  query_id: "",
  status: "",
};

export const getAllClinicJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/clinic/getAllClinics",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getReasonJson: CliniqallyAdminReasonAppDataClassRestApiJson = {
  url: "v1/admin/query/list-query-reason",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteReasonJson: CliniqallyAdminReasonAppDataClassRestApiJson = {
  url: "v1/admin/query/delete-query-reason",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const getAllTypeJson: CliniqallyAdminReasonAppDataClassRestApiJson = {
  url: "v1/admin/query/getAllQueryType",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postReasonJson: CliniqallyAdminReasonAppDataClassRestApiJson = {
  url: "v1/admin/query/create-query-reason",
  restMethod: RestMethod.post,
  authentication: true,
  query_reason: "",
  status: "",
  type_id: "",
  service_consumer: false,
  service_provider: false,
};

export const updateReasonJson: CliniqallyAdminReasonAppDataClassRestApiJson = {
  url: "v1/admin/query/update-query-reason",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
  query_reason: "",
  status: "",
  query_type: "",
  service_provider: false,
  service_consumer: false,
  type_id: "",
};

export const getReasonByIdJson: CliniqallyAdminReasonAppDataClassRestApiJson = {
  url: "v1/admin/query/get-query-reason",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getSubscriptionPlanJson: CliniqallyAdminSubscriptionPlanAppDataClassRestApiJson = {
  url: "v1/admin/subscription/list-subscription-plan",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getAllTaxSubscriptionPlanJson: CliniqallyAdminSubscriptionPlanAppDataClassRestApiJson = {
  url: "v1/admin/taxType/list-tax-type",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postSubscriptionPlanJson: CliniqallyAdminSubscriptionPlanAppDataClassRestApiJson = {
  url: "v1/admin/subscription/subscription-plan",
  restMethod: RestMethod.post,
  authentication: true,
  name: "",
  description: "",
  status: "",
  subscription_interval: "",
  user_type: "",
  subscription_type: "",
  subscription_plan_type: "",
  provider_limit: "",
  discount: "",
  subscription_days: "",
  discount_type: "",
  unit_price: "",
  tax: "",
  currency: "",
};

export const getSubscriptionPlanByIdJson: CliniqallyAdminSubscriptionPlanAppDataClassRestApiJson = {
  url: "v1/admin/subscription/get-subscription-plan",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updateSubscriptionPlanJson: CliniqallyAdminSubscriptionPlanAppDataClassRestApiJson = {
  url: "v1/admin/subscription/update-subscription-plan",
  restMethod: RestMethod.post,
  authentication: true,
  subscription_id: "",
  status: "",
};

export const getClinicSubscriptionByIdJson: CliniqallyAdminSubscriptionPlanAppDataClassRestApiJson = {
  url: "v1/admin/subscription/get-clinic-subscription",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const postClinicLicenseJson: CliniqallyAdminClinicLicenseAppDataClassRestApiJson = {
  url: "v1/admin/subscription/assign-clinic-subscription",
  restMethod: RestMethod.post,
  authentication: true,
  start_date: "",
  status: "",
  subscription_status: "",
  clinic_id: "",
  is_paid: false,
  is_expired: false,
  subscription_plan_id: "",
  comments: "",
};

export const getProvidersJson: CliniqallyAdminProviderClinicAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/get-service-provider-without-clinic",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteProviderJson: CliniqallyAdminProviderClinicAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/delete-service-provider",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const updateProviderClinicJson: CliniqallyAdminProviderClinicAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/assign-service-provider-clinic",
  restMethod: RestMethod.post,
  authentication: true,
  centerId: "",
  providerId: "",
  userType: "",
  status: "",
};

export const checkProviderJson: CliniqallyAdminAppVerifyproviderRestApiJson = {
  url: "v1/admin/service-provider/get-service-provider-verify",
  restMethod: RestMethod.post,
  authentication: true,
  email: "",
  country_code: "",
  mobile_number: "",
};

export const searchClinicJson: CliniqallySearchClinicAdminAppRestApiJson = {
  url: "v1/admin/clinic/search-clinic",
  restMethod: RestMethod.post,
  authentication: true,
  email: "",
  country_code: "",
  phone_number: "",
  name: "",
  cliniqally_center_id: "",
};

export const getCreateProfileGetDetailsJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/create-profile-get-details",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getActiveStateJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/service-provider/get-states",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getActiveCityJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/service-provider/get-cities",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getActiveSubSpecialJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/service-provider/get-sub-specialization",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getProviderJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/get-service-provider",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getProviderRegDetailsByIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/get-service-provider-by-id",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const postProviderJson: CliniqallyAdminAppAddproviderRestApiJson = {
  url: "v1/admin/service-provider/create-service-provider",
  restMethod: RestMethod.post,
  authentication: true,
  firstName: '',
  lastName: '',
  pincode: '',
  about: '',
  userType: '',
  email: '',
  language: [],
  specialization: [],
  degree: [],
  awards: '',
  membership: '',
  dob: '',
  gender: '',
  registrationNumber: '',
  registrationName: '',
  timezone: '',
  address: '',
  country: '',
  state: '',
  city: '',
  providerAgreement: false,
  whatsappNotifications: false,
  blood_donation: false,
  countryCode: '',
  mobile: '',
  bloodGroup: '',
  marketing_channel_id: '',
};

export const updateProviderJson: CliniqallyUpdateAdminAppAddProviderRestApiJson = {
  url: "v1/admin/service-provider/update-service-provider",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  firstName: '',
  lastName: '',
  pincode: '',
  about: '',
  email: '',
  dob: '',
  gender: '',
  timezone: '',
  address: '',
  country: '',
  state: '',
  city: '',
  countryCode: '',
  mobileNumber: '',
  blood_group: '',
};

export const getMedicalProcedureJson: CliniqallyAdminMedicalProcedureAppDataClassRestApiJson = {
  url: "v1/admin/medicalProcedure/list-medical-procedure",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteMedicalProcedureJson: CliniqallyAdminMedicalProcedureAppDataClassRestApiJson = {
  url: "v1/admin/medicalProcedure/delete-medical-procedure",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postMedicalProcedureJson: CliniqallyAdminMedicalProcedureAppDataClassRestApiJson = {
  url: "v1/admin/medicalProcedure/add-medical-procedure",
  restMethod: RestMethod.post,
  authentication: true,
  status: "",
  procedureName: "",
  panel: "",
  procedureCode: "",
  procedurePrice: "",
  taxApplicable: "",
  taxType: "",
  description: "",
};

export const getAllTaxJson: CliniqallyAdminMedicalProcedureAppDataClassRestApiJson = {
  url: "v1/admin/taxType/list-tax-type",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getMedicalProcedureByIdJson: CliniqallyAdminMedicalProcedureAppDataClassRestApiJson = {
  url: "v1/admin/medicalProcedure/get-medical-procedure",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getHabitJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/habit/list-habit",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteHabitJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/habit/delete-habit",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postHabitJson: CliniqallyCreateAdminHabitAppDataClassRestApiJson = {
  url: "v1/admin/habit/create-habit",
  restMethod: RestMethod.post,
  authentication: true,
  habit: "",
  description: "",
  status: "",
};

export const getHabitByIdJson: CliniqallyCreateAdminHabitAppDataClassRestApiJson = {
  url: "v1/admin/habit/get-habit",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getComplaintJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/list-chief-complaints",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteComplaintJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/delete-chief-complaints",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postComplaintJson: CliniqallyCreateAdminComplaintAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/create-chief-complaints",
  restMethod: RestMethod.post,
  authentication: true,
  chief_complaint: "",
  chiefComplaintDescription: "",
  status: "",
  specialization: ""
};

export const postEditComplaintJson: CliniqallyUpdateAdminComplaintAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/update-chief-complaints",
  restMethod: RestMethod.post,
  authentication: true,
  chiefComplaint: "",
  chiefComplaintDescription: "",
  status: "",
  specialization: "",
  pathParameters: "",
};

export const GetComplaintJson: CliniqallyCreateAdminComplaintAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/get-chiefComplain",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const GetSpecializationJson: CliniqallyCreateAdminComplaintAppDataClassRestApiJson = {
  url: "v1/admin/service-provider/list-specialization",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getTaxJson: CliniqallyCreateAdminTaxAppDataClassRestApiJson = {
  url: "v1/admin/taxType/list-tax-type",
  restMethod: RestMethod.get,
  authentication: true,
};

export const deleteTaxJson: CliniqallyCreateAdminTaxAppDataClassRestApiJson = {
  url: "v1/admin/taxType/delete-tax-type",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const postTaxJson: CliniqallyCreateAdminTaxAppDataClassRestApiJson = {
  url: "v1/admin/taxType/add-tax-type",
  restMethod: RestMethod.post,
  authentication: true,
  taxName: "",
  taxValue: "",
  description: "",
  status: "",
};

export const updateTaxJson: CliniqallyCreateAdminTaxAppDataClassRestApiJson = {
  url: "v1/admin/taxType/update-tax-type",
  restMethod: RestMethod.post,
  authentication: true,
  taxName: "",
  description: "",
  status: "",
  pathParameters: "",
};

export const getTaxByIdJson: CliniqallyCreateAdminTaxAppDataClassRestApiJson = {
  url: "v1/admin/taxType/get-tax-type",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getLeaveJson: CliniqallyCreateAdminLeaveAppDataClassRestApiJson = {
  url: "v1/admin/leaveDetails/list-leave",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postLeaveJson: CliniqallyCreateAdminLeaveAppDataClassRestApiJson = {
  url: "v1/admin/leaveDetails/create-leaveDetail",
  restMethod: RestMethod.post,
  authentication: true,
  leaveDetail: "",
  reason: "",
  status: "",
};

export const getLeaveByIdJson: CliniqallyCreateAdminLeaveAppDataClassRestApiJson = {
  url: "v1/admin/leaveDetails/get-leaveDetail",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getProviderListJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/verification/list-confirmed-provider",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getUnConfirmedProviderListJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/verification/list-un-confirmed-provider",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getProviderByIdJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/verification/get-provider-details",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updateProviderVerificationJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/verification/update-verification",
  restMethod: RestMethod.post,
  authentication: true,
  description: "",
  status: "",
  pathParameters: "",
};
export const getAllClinicListJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/v1/admin/clinic/getAllClinics",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getUnConfirmedProviderVerificationJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/provider-verification/list-un-confirmed-provider",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getProviderListConfirmedProviderJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/provider-verification/list-confirmed-provider",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getAllUnconfirmedCenterListJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/center-verification/list-unconfirmed-center",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getConfirmedCenterListJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/center-verification/list-confirmed-center",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getProviderVerificationByIdJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/provider-verification/get-provider-details",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export interface documentInfoProvider {
  reason: string;
  status: string;
  url: string;
  _id: string;
}

export interface providerDocumentType {
  documentsRequired:
  {
    qualification_certificate: documentInfoProvider;
    doctor_registration_proof: documentInfoProvider;
    doctor_photo_id: documentInfoProvider;
    user_photo_id: documentInfoProvider;
  }[]
}

export interface providerDocumentDetailsCardType {
  documentsRequired:
  {
    qualification_certificate: documentInfoProvider;
    doctor_registration_proof: documentInfoProvider;
    doctor_photo_id: documentInfoProvider;
    user_photo_id: documentInfoProvider;
  }[];
  length: number;
}
export interface providerExtractedDocumentType {
  qualification_certificate: documentInfoProvider;
  doctor_registration_proof: documentInfoProvider;
  doctor_photo_id: documentInfoProvider;
  user_photo_id: documentInfoProvider;
}

export const updateProviderDocumentVerificationJson: CliniqallyProviderDocumentAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/provider-verification/update-verification",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
  qualification_certificate_object: {
    _id: "",
    status: "",
    reason: " ",
  },
  doctor_registration_proof_object: {
    _id: "",
    status: "",
    reason: " ",
  },
  doctor_photoId_object: {
    _id: "",
    status: "",
    reason: " ",
  },
  user_photoId_object: {
    _id: "",
    status: "",
    reason: " ",
  },

};

export class CliniqallyProviderDocumentAdminVerificationAppDataClassRestApiJson extends CliniqallyCreateAdminAppDataClassRestApiJson {
  qualification_certificate_object: {
    _id?: string;
    status?: string;
    reason?: string;
  };
  doctor_registration_proof_object: {
    _id?: string;
    status?: string;
    reason?: string;
  };
  doctor_photoId_object: {
    _id?: string;
    status?: string;
    reason?: string;
  };
  user_photoId_object: {
    _id?: string;
    status?: string;
    reason?: string;
  };
}


export const getClinicVerificationByIdJson: CliniqallyCreateAdminVerificationAppDataClassRestApiJson = {
  url: "v1/admin/center-verification/get-center-details",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export interface documentRequiredNbChat {
  url: any;
  createdAt: string | number | Date;
  status: any;
  reason: any;
  clinic_registration_certificate: {
    reason: string;
    status: string;
    url: string;
    createdAt: string;
  };
}

export interface centerDocument {
  _id: string;
  documentsRequired: documentRequired[];
}

export interface documentRequired {
  clinic_registration_certificate: {
    url?: string;
    reason?: string;
    status?: string;
    createdAt?: string;
  };
  _id: string;
}

export interface adminDetailsType {
  country_code: string;
  email: string;
  mobile: string;
  name: string;
}

export interface centerDocumentsLogs {
  clinic_registration_certificate: any;
  url: string;
  reason?: string | undefined;
  status?: string | undefined;
};

export interface providerDetails {
  center_id: string;
  country_code: string | null;
  createdAt: string;
  email: string;
  first_name: string;
  last_name: string;
  mobile: string;
  profile_picture: string | null;
  provider_id: string;
  type: string;
  user_type: string;
  _id: string;
}

export interface extractedDocumentRequiredReg {
  createdAt: string;
  url: string;
  reason?: string | undefined;
  status?: string | undefined;
}[];

export const updateNewCenterVerificationJson: CliniqallyAdminAcceptDocumentAppDataClassRestApiJson = {
  url: "v1/admin/center-verification/update-verification",
  restMethod: RestMethod.post,
  authentication: true,
  clinic_registration_certificate_object: {
    _id: "",
    status: "",
    reason: "",
  },
  pathParameters: "",
};

export interface documentStatusType {
  _id: string;
  status: string;
  reason: string;
}

export const getFAQJson: CliniqallyAdminFAQAppDataClassRestApiJson = {
  url: "v1/admin/faq/list-faq",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postFAQJson: CliniqallyAdminFAQAppDataClassRestApiJson = {
  url: "v1/admin/faq/create-faq",
  restMethod: RestMethod.post,
  authentication: true,
  faqTitle: "",
  faqBody: "",
  status: "",
  mobileIos: false,
  mobileAndroid: false,
  serviceProvider: false,
  serviceConsumer: false,
  web: false,
};

export const getFAQByIdJson: CliniqallyAdminFAQAppDataClassRestApiJson = {
  url: "v1/admin/faq/get-faq",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const updateFAQJson: CliniqallyAdminFAQAppDataClassRestApiJson = {
  url: "v1/admin/faq/update-faq",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  faqTitle: "",
  faqBody: "",
  status: "",
  mobileIos: false,
  mobileAndroid: false,
  serviceProvider: false,
  serviceConsumer: false,
  web: false,
};

export const deleteFAQJson: CliniqallyAdminFAQAppDataClassRestApiJson = {
  url: "v1/admin/faq/delete-faq",
  restMethod: RestMethod.post,
  authentication: true,
  pathParameters: "",
};

export const specialityImageJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/speciality/upsert-clinic-speciality-image",
  restMethod: RestMethod.post,
  authentication: true,
  contentType: ContentType.formData,
  uploadFormData: new FormData()
};

export const globalSearchConsumerJson: CliniqallyGlobalSearchConsumerAdminAppRestApiJson = {
  url: "v1/admin/clinic/search-consumer-detail",
  restMethod: RestMethod.post,
  authentication: true,
  email: "",
  country_code: "",
  mobile: "",
  first_name: "",
  last_name: "",
  cliniqally_consumer_id: "",
};

export const getClinicDetailsByConsumerJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/clinic/getClinicDetailsByConsumer",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export interface consumerDetailsType {
  consumer_id: string;
  clinicdetails: consumerClinicDetailType[];
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  mobile: string;
}

export interface consumerClinicDetailType {
  clinicId: string;
  clinicName: string;
  appointmentDetails: consumerAppointmentDetails[];
}

export interface consumerAppointmentDetails {
  providername: string;
  status: string;
  token: string;
  provider_id: string;
  name: string;
  consumer_id: string;
  center_id: string;
  appointment_time: string;
  appointment_mode: string;
  appointment_id: string;
  appointment_date: string;
  invoiceDetails: invoiceDetails[];
}

export const viewAppointmentGlobalSearchConsumerJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/clinic/getAppointmentDetailByConsumer",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getClinicAppointmentByCenterIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/clinic/get-clinic-detail",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const getProvidersInClinicByCenterIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/clinic/get-provider-details-by-clinic",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export interface providerDetailsGlobalSearch {
  name: string;
  center_id: string;
  provider_details: providerDetailsGlobalSearchType[];
}

export interface providerDetailsGlobalSearchType {
  Address: string;
  BloodGroup: string;
  City: string;
  Country: string;
  CountryCode: string;
  EmailId: string;
  FirstName: string;
  Gender: string;
  LastName: string;
  Mobile: number;
  CliniqallyProviderId: string;
  ProviderId: string;
}

export interface consumerAppointmentDetailsType {
  consumerfirstname: string;
  consumerlastname: string;
  consumerid: string;
  appointment_details: appointmentType[];
}

export interface appointmentType {
  ProviderFirstName: string;
  ProviderLastName: string;
  appointment_date: string;
  appointment_fee: string;
  appointment_id: string;
  cliniqally_appointment_id: string;
  is_appointment_fee_paid: string;
  status: string;
  token: string;
  appointment_mode: string;
  appointment_time: string;
}

export interface combinedArray {
  ProviderFirstName: string;
  ProviderLastName: string;
  appointment_date: string;
  appointment_fee: string;
  appointment_id: string;
  cliniqally_appointment_id: string;
  is_appointment_fee_paid: string;
  status: string;
  token: string;
  appointment_mode: string;
  appointment_time: string;
  invoiceDetails: invoiceDetails[];
  formattedAppointmentDate: string;
  formattedAppointmentTime: string;
}
export interface clinicAppointmentsDetails {
  ClinicName: string;
  center_id: string;
  consumerDetails: consumerDetails[];
  token: number;
  providerName: string;
  provider_id: string;
}

export interface consumerDetails {
  appointmentDate: string;
  appointmentFeePaymentStatus: string;
  appointmentId: string;
  appointmentMode: string;
  appointmentStatus: string;
  appointmentTime: string;
  consumerEmail: string;
  consumerFirstName: string;
  consumerId: string;
  consumerLastName: string;
  invoiceDetails: invoiceDetails[];
}

export interface invoiceDetails {
  invoice_id: string;
  invoice_number: string;
  total: string;
}

export const getClinicAppointmentByProviderIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/clinic/getAppointmentDetailsByProvider",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export interface clinicAppointmentsByProviderIdDetails {
  providerName: string;
  providerfirstname: string;
  providerlastname: string;
  providerid: string;
  appointment_details: patientAppointmentDetails[];
}

export interface mergedArray {
  appointment_date: string;
  appointment_time: string;
  appointment_fee: string;
  appointment_id: string;
  appointment_mode: string;
  is_appointment_fee_paid: string;
  consumerFirstName: string;
  consumerLastName: string;
  cliniqally_appointment_id: string;
  invoiceDetails: invoiceDetails[];
  providerfirstname: string;
  providerlastname: string;
  status: string;
  token: string;
  formattedAppointmentDate: string;
  formattedAppointmentTime: string;
}

export const getPatientsAppointmentByAppointmentIdJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/clinic/get-appointment-details",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export interface patientAppointmentDetails {
  provideId: string;
  appointment_date: string;
  appointment_time: string;
  appointment_fee: string;
  appointment_id: string;
  appointment_mode: string;
  is_appointment_fee_paid: string;
  appointmentTime: string;
  consumerEmail: string;
  consumerFirstName: string;
  consumerLastName: string;
  consumerfirstname: string;
  cliniqally_appointment_id: string;
  consumerlastname: string;
  invoiceDetails: invoiceDetails[];
  providerfirstname: string;
  providerlastname: string;
  status: string;
  token: string;
}

export const globalSearchProviderJson: CliniqallyAdminAppGlobalSearchProviderRestApiJson = {
  url: "v1/admin/clinic/search-provider-detail",
  restMethod: RestMethod.post,
  authentication: true,
  email: "",
  country_code: "",
  mobile: "",
  first_name: "",
  last_name: "",
  cliniqally_provider_id: "",
};

export interface providerDetailsType {
  provider_id: string;
  clinicdetails: clinicDetailType[];
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  mobile: string;
  cliniqally_provider_id: string;
}
export interface clinicDetailType {
  clinicId: string;
  clinicName: string;
  appointment_details: providerAppointmentDetails[];
}

export const getClinicDetailsByProviderJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/clinic/getClinicDetailsByProvider",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export const viewAppointmentGlobalSearchProviderJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/clinic/getAppointmentDetailsByProvider",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export interface providerAppointmentDetails {
  providerfirstname: string;
  providerlastname: string;
  providerid: string;
  appointment_details: providerAppointmentType[];
}
export interface providerAppointmentType {
  consumerFirstName: string;
  consumerLastName: string;
  appointment_date: string;
  appointment_fee: string;
  appointment_id: string;
  cliniqally_appointment_id: string;
  is_appointment_fee_paid: string;
  status: string;
  token: string;
  appointment_mode: string;
  appointment_time: string;
}

export interface providerAppointmentTypeMergedArray {
  consumerFirstName: string;
  consumerLastName: string;
  appointment_date: string;
  appointment_fee: string;
  appointment_id: string;
  cliniqally_appointment_id: string;
  is_appointment_fee_paid: string;
  status: string;
  token: string;
  appointment_mode: string;
  appointment_time: string;
  invoiceDetails: invoiceDetails[];
  formattedAppointmentDate: string;
  formattedAppointmentTime: string;
}

export const globalSearchCenterJson: CliniqallyAdminAppGlobalSearchCenterRestApiJson = {
  url: "v1/admin/clinic/search-clinic-detail",
  restMethod: RestMethod.post,
  authentication: true,
  email: "",
  country_code: "",
  mobile: "",
  name: "",
  cliniqally_center_id: "",
};

export interface centerDetailsType {
  address: string;
  center_id: string;
  city: string;
  cliniqally_center_id: string;
  country: string;
  country_code: string;
  email: string;
  location: string;
  name: string;
  phone_number: string;
  state: string;
}

export const postPaymentAddClinicApiAdminJson: CliniqallyAdminAppPaymentAddClinicRestApiJson = {
  url: "v1/admin/payment-mode/add",
  restMethod: RestMethod.post,
  authentication: true,
  provider_id: "",
  center_id: "",
  mode_name: "",
  mode_description: "",
  status: "",
  payment_mode_types: "",
  is_available_offline: false,
  is_available_online: false,
  account_holder_name: "",
  account_number: "",
  bank_branch: "",
  bank_name: "",
  ifsc_code: ""
};

// API integration related interfaces ends
// stories
export const getStoriesJson: CliniqallyCreateAdminAppDataClassRestApiJson = {
  url: "v1/admin/stories/list-stories",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postTextStoriesJson: CliniqallyAdminAppTextStoriesRestApiJson = {
  url: "v1/admin/stories/create-text-stories",
  restMethod: RestMethod.post,
  authentication: true,
  text: "",
};

export const postImageStoriesJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/stories/create-image-stories",
  restMethod: RestMethod.post,
  authentication: true,
  contentType: ContentType.formData,
  uploadFormData: new FormData()
};

export const getPaymentModeByClinicIdJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/payment-mode/list",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};

export interface paymentMode {
  is_available_offline: boolean;
  is_available_online: boolean;
  mode_description: string;
  mode_name: string;
  payment_mode_id: string;
  payment_mode_types: string;
  status: string;
}

export const updateClinicJson: CliniqallyAdminUpdateClinicAppDataClassRestApiJson = {
  url: "v1/admin/clinic/update-clinic",
  restMethod: RestMethod.patch,
  authentication: true,
  pathParameters: "",
  cliniqally_fee_disabled: false,
};

export const getClinicByIdJson: CliniqallyAdminAppBaseRestApiJson = {
  url: "v1/admin/clinic/get-clinic",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: "",
};
// API integration related interfaces ends
