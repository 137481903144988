import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getToken, removeToken } from '../services/standard';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private router: Router) { }
  get token() {
    const token = getToken();
    return token;
  }
  logout() {
    removeToken();
    this.router.navigate(['/login']);
  }
}
