import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'vaas-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})

export class ImageModalComponent {
  image: String;
  constructor(protected ref: NbDialogRef<ImageModalComponent>) {
  }

  close() {
    this.ref.close();
  }
}